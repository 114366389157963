import "./styles.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import SplitType from "split-type";
import $ from "jquery";

window.addEventListener("DOMContentLoaded", (event) => {

    let typeSplit = new SplitType(".animation", {
      types: "words",
      tagName: "span",
    });
  
    $(".animation").each(function (index) {
      let tl = gsap.timeline({});
      tl.from($(this).find(".word"), {
        duration: .2,
        ease: "expo.out",
        stagger: { amount: .4 },
        scaleX: 0,
        scaleY: 0,
        transformOrigin: "center center",
      });
  
      ScrollTrigger.create({
        trigger: $(this),
        start: "top bottom",
        onEnter: () => tl.restart(),
      });
    });
  });



const _tickerTextArray = ["Frontend Design und Entwicklung", "Freie Radikale"];
let currentItem = 0;
const _ticker = document.getElementById("ticker");

setInterval(() => {
  _ticker.textContent = _tickerTextArray[currentItem];
  currentItem = (currentItem + 1) % _tickerTextArray.length;
}, 3000);
